import React from "react";
export const renderGoogleAds = () => {
    const scripts: any = [];
    scripts.push(
		<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7448140968173959"
			 crossorigin="anonymous"></script>
    );

    return scripts;
  };
  